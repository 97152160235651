import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Clean 5×1+1+1 (quick drop singles) at 90% 1RM`}</p>
    <p>{`Front Squats 4×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`15-Calorie Row`}</p>
    <p>{`12-Power Cleans (135/95)`}</p>
    <p>{`9-Bar Facing Burpees`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`The CrossFit Open starts this Thursday, February 21st!
 **`}</strong>{`Register today at: Games.CrossFit.com.   The announcement for 19.1
will take place at 8:00pm.  We’ll be throwing down each Friday night
starting this Friday the 22nd from 4:30-6:30 so the workouts will be
Friday’s wod each week.  Redo’s will be Saturday’s and Sunday’s after
our classes.***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You can now schedule a massage with Jennifer May, our newest member
and massage therapist! Jennifer is offering a special for the month of
February. You can get a full hour massage for \\$50!! The massage room is
located on the left as soon as you walk into the gym. She works by
appointment only, so email her to schedule.
 `}<a parentName="em" {...{
            "href": "mailto:j.zenmassagetherapy@gmail.com"
          }}>{`j.zenmassagetherapy@gmail.com`}</a>{`.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      